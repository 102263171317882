import React from 'react'
import Jitsi from "./Jitsi";

function Meet() {
  
    return <Jitsi uuid="sigtrack-mission1"

/>;

return <Jitsi uuid="sigtrackmission2"
/>;


}
export default Meet