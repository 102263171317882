import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyC4BQjpOl0T2j6SiDJnAvwI4txzrNH0RJw",
  authDomain: "sigtrack-stream.firebaseapp.com",
  projectId: "sigtrack-stream",
  storageBucket: "sigtrack-stream.appspot.com",
  messagingSenderId: "599927755495",
  appId: "1:599927755495:web:ca8bee55c2c598e8f70842",
  measurementId: "G-RFPZFML8VL"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);