import React from 'react'
import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
import "../App.css";
import DefaultLayout from '../components/DefaultLayout';
import * as markerjs2 from 'markerjs2';

function Map() {
  
    const { isLoaded } = useLoadScript({
        googleMapsApiKey:'AIzaSyDkw3a_XLgmpbUFB1yuuNj3o5cFlhP7HCo',
      });
      const center = useMemo(() => ({lat:5.636096,lng:-0.1572864}), []);
  return (
    <DefaultLayout>
      
    <div className='App'>
    {!isLoaded ? (
        <h1>Loading...</h1>
      ) : (
        <GoogleMap
          mapContainerClassName="map-container"
          center={center}
          zoom={10}
        >
          <Marker position={{ lat: 18.52043, lng: 73.856743 }} />
        </GoogleMap>
      )}
    </div>
    </DefaultLayout>
  )
}

export default Map