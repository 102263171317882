import {  getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc} from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
//import Loader from '../components/Loader';
import { app, fireDb } from '../firebaseConfig';
import pic from "../images/SIGTRACK.png";


function Login() {
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const navigate = useNavigate();
    //const [setIsLoggedIn] = useState("");

    const login=()=>{
        // try {
            const auth = getAuth(app);
           

signInWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in
    
    const user = userCredential.user; 

    getDoc(doc(fireDb,"users",user.uid)).then((user) => {
        localStorage.setItem("sigtrack-user",JSON.stringify({...user.data(),id:user.id}));
     
        

    });
    toast.success("Login successfully");
 //setDoc(doc(fireDb,"users",user.uid),userData)
   // console.log(user);
    // ...
    navigate('/',)
})
  .catch((error) => {
   // console.log(error);
   toast.error("Login failed check credentials");
   // const errorCode = error.code;
    //const errorMessage = error.message;
    //console.log(error.message);
    // ..
  });
 
        // } catch (error) {
            
        // }
    }
    useEffect(()=>{
if(localStorage.getItem('sigtrack-user')){
    navigate('/');
}
    });
  return (
    <div className=' h-screen flex justify-center items-center'>
        {/* <Loader/> */}
     
      
<div className='w-[420px] flex flex-col space-y-5 card p-10 mt-10'>

            <h1 className='text-4xl text-dark font-semibold mt-20'>Sigtrack Login</h1>
            

            <hr/>
            <img src={pic} height="30px" width="50px"/>
            <input type="text"
             value={email}
            onChange={(e) => setEmail(e.target.value)} 
            className='border border-gray-300 h-10 rounded-sm focus:border-gray-500 pl-5'
            placeholder='Enter email'
            />
            <input type="text" 
              value={password}
              onChange={(e) => setPassword(e.target.value)} 
            className='border border-gray-300 h-10 rounded-sm focus:border-gray-500 pl-5'
            placeholder='Enter Password'
            />
            <div className='flex justify-end' >
                <button className='bg-primary h-10 rounded-lg text-white px-10' onClick={login}>Sign In</button>

            </div>
            <hr/>
            <Link to='/register' className='text-xl text-primary'>Not Registered click here to register</Link>
        </div>

  
        
      
    </div>
  )
}

export default Login