import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { fireDb,app } from '../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';

import logo from '../images/SIGTRACK.png'


function Register() {
    const [email,setEmail] = useState('');
    const [password,setPassword] = useState('');
    const [confirmPassword,setConfirmPassword] = useState('');
    const navigate = useNavigate();

    const register=()=>{
        // try {
           
const auth = getAuth(app);
createUserWithEmailAndPassword(auth, email, password)
  .then((userCredential) => {
    // Signed in
    const user = userCredential.user;
    console.log(user) 
    const userData = {
        email:user.email,
        profilePicUrl:'',
        bio:'Hi this is Kwesi from sigtrack',
        id:user.uid,

    }

    setDoc(doc(fireDb,'users',user.uid),userData);
    toast.success("Registration successful");
    navigate('/login');
    
    console.log(user);
    // ...
  })
  .catch((error) => {
    //console.log(error);
    toast.error("Something went wrong");
   // const errorCode = error.code;
    //const errorMessage = error.message;
    //console.log(error.message);
    // ..
  });
 
        // } catch (error) {
            
        // }
    }
    useEffect(()=>{
        if(localStorage.getItem('sigtrack-user')){
            navigate('/', { replace: true });
        }
            });
  return (
    <div className='bg-blue h-screen flex justify-center items-center'>
      

    <div className='w-[420px] flex flex-col space-y-5 card p-10'>
                <h1 className='text-4xl text-white font-semibold'>Sigtrack Registration</h1>
                <img src={logo} className="" width="70" height="70"/>
                <hr/>
                <input type="text" 
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className='border border-gray-300 h-10 rounded-sm focus:border-gray-500 pl-5'
                placeholder='Enter email'
                />
                <input type="text" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}

                className='border border-gray-300 h-10 rounded-sm focus:border-gray-500 pl-5'
                placeholder='Enter Password'
                />
                 <input type="text" 
                 value={confirmPassword}
                 onChange={(e) => setConfirmPassword(e.target.value)}
                 className='border border-gray-300 h-10 rounded-sm focus:border-gray-500 pl-5'
                placeholder='Confirm Password'
                />
                <div className='flex justify-end' >
                    <button className='bg-primary h-10 rounded-lg text-white px-10' onClick={register}>Sign Up</button>
    
                </div>
                <hr/>
                <Link to='/login' className='text-xl text-primary'>Already have an account Login here</Link>
            </div>
    
      
            
          
        </div>
  )
}

export default Register