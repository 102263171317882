import React from 'react'
import Header from './Header'

function DefaultLayout(props) {
  return (
    <div className=''>
  <Header/>
 
 <div className="">
    {props.children}
  </div>
  </div>
  )
}

export default DefaultLayout