import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../images/SIGTRACK.png'

function Header() {

    const menuItems = [
        {
            title:'Home',
            path:'/'
        },
        {
            title:'Add Stream',
            path:'/add-post'
        },
        {
            title:'Map',
            path:'/map'
        },
        {
            title:'Request',
            path:'http://127.0.0.1:8000'
        },

        {

            title:'Annotate',
            path:'/annotate',
        },
    ]

  return (


    <div className='p-5 bg-blue'>
        <div className='flex space x-10 justify-start item-center text-white'>                                                                                                                                                                                                                                                                                                                                                                                                                                  
         <Link to="/"><img src={logo} className="pb-50 mt-5" width="70" height="70"/></Link>

        </div>
        <div className='flex space-x-10 justify-end item-center text-white'>
            {menuItems.map((item)=>{
                return <Link className={'text-white-600 bg-blue'} to={`${item.path}`}>{item.title}</Link>
            }
            )}

        </div>
    </div>
  )
}

export default Header