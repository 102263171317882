import React from 'react'
import { Link } from 'react-router-dom'
import DefaultLayout from '../components/DefaultLayout'


function Home() {

    // const logout = () => {
    //     localStorage.removeItem('sigtrack-user');
    //     setIsLoggedin(false);
    //   };
  return (
    <DefaultLayout>
        <div>
        <Link to='/add-post'><button class="bg-primary text-white font-bold py-2 px-4 rounded mt-10 mx-5">
  Add room
</button></Link>
<div class="relative overflow-x-auto shadow-md sm:rounded-lg">
    
    <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
        <caption class="p-5 text-lg font-semibold text-left text-gray-900 bg-white dark:text-white dark:bg-gray-800">
            Sigtrack Rooms
            <p class="mt-1 text-sm font-normal text-gray-500 dark:text-gray-400">Join a meeting on sigtrack</p>
        </caption>
        <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
                <th scope="col" class="px-6 py-3">
                    Room Name
                </th>
                <th scope="col" class="px-6 py-3">
                    Date Created
                </th>
          
                <th scope="col" class="px-6 py-3">
                    Live Video
                </th>
              
            </tr>
        </thead>
        <tbody>
          
            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Sigtrack Room1
                </th>
                <td class="px-6 py-4">
                    19-02-23
                </td>
             
         
                <td class="px-6 py-4">
                <Link to='/meet'><button class="bg-primary text-white font-bold py-2 px-4 rounded">
  Join
</button></Link>
                </td>
            </tr>

            <tr class="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                <th scope="row" class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    Meet Room1
                </th>
                <td class="px-6 py-4">
                    21-03-23
                </td>
             
         
                <td class="px-6 py-4">
                <Link to='/meet'><button class="bg-primary text-white font-bold py-2 px-4 rounded">
  Join
</button></Link>
                </td>
            </tr>
  
        </tbody>
    </table>
</div>
<Link to='/login'><button class="bg-primary text-white font-bold py-2 px-4 rounded mt-10 mx-5">
  Logout
</button></Link>

        </div>

    </DefaultLayout>
  )
}

export default Home