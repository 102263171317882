import React from 'react'
import DefaultLayout from '../components/DefaultLayout';
import * as markerjs2 from 'markerjs2';

function Annotate() {
  return (
    <DefaultLayout>
 <div className='App'>
       <h2>GH</h2>
       <h3>Header</h3> 
    </div>
    </DefaultLayout>
   
  )
}

export default Annotate