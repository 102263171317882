//import logo from './logo.svg';
//import './App.css';
import {BrowserRouter,Routes,Route,Navigate} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddPost from './pages/AddPost';
import Meet from './pages/Meet';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Registers from './pages/Registers';
import Logins from './pages/Logins';
import RegisterPage from './pages/RegisterPage';
import Map from './pages/Map';
import Annotate from './pages/Annotate';
import Otplogin from './pages/Otplogin';

function App() {
  return (
<>
    <ToastContainer/>
<BrowserRouter>
<Routes>
  <Route path="/" element={<Home/>}/>
 <Route path="/registers" element={<Registers/>}/>
  <Route path="/login" element={<Login/>}/>
  <Route path="/logins" element={<Logins/>}/>
  <Route path="/meet" element={<Meet/>}/>
  <Route path="/register" element={<Register/>}/>
  <Route path="/sign-up" element={<RegisterPage/>}/>
  <Route path="/map" element={<Map/>}/>
  <Route path="/annotate" element={<Annotate/>}/>
  <Route path="/otp-login" element={<Otplogin/>}/>
  

  <Route path="/add-post" element={<AddPost/>}/>

</Routes>
</BrowserRouter>
   </>   
  );
}
 function ProtectedRoute({children}){
 if(localStorage.getItem('sigtrack-user')){
  
   return children 
  

}
 else{



  <Navigate to='/login'/>
 }
 }
export default App;
